const __pages_import_0__ = () => import("/src/pages/terms.vue");
const __pages_import_1__ = () => import("/src/pages/start.vue");
const __pages_import_2__ = () => import("/src/pages/select.vue");
const __pages_import_3__ = () => import("/src/pages/receipt.vue");
const __pages_import_4__ = () => import("/src/pages/policy.vue");
const __pages_import_5__ = () => import("/src/pages/intro.vue");
import __pages_import_6__ from "/src/pages/index.vue";
const __pages_import_7__ = () => import("/src/pages/[...error].vue");
const __pages_import_8__ = () => import("/src/pages/character/[name].vue");
const __pages_import_9__ = () => import("/src/pages/dress-up/[name].vue");
const __pages_import_10__ = () => import("/src/pages/result/[name].vue");

const routes = [{"name":"terms","path":"/terms","component":__pages_import_0__,"props":true},{"name":"start","path":"/start","component":__pages_import_1__,"props":true},{"name":"select","path":"/select","component":__pages_import_2__,"props":true},{"name":"receipt","path":"/receipt","component":__pages_import_3__,"props":true},{"name":"policy","path":"/policy","component":__pages_import_4__,"props":true},{"name":"intro","path":"/intro","component":__pages_import_5__,"props":true},{"name":"index","path":"/","component":__pages_import_6__,"props":true},{"name":"error","path":"/:error(.*)*","component":__pages_import_7__,"props":true},{"name":"character-name","path":"/character/:name","component":__pages_import_8__,"props":true},{"name":"dress-up-name","path":"/dress-up/:name","component":__pages_import_9__,"props":true},{"name":"result-name","path":"/result/:name","component":__pages_import_10__,"props":true}];

export default routes;
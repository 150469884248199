import { defineStore } from 'pinia'

export const useStore = defineStore('store', {
  state: () => ({
    actor: null,
  }),
  actions: {
    setActor(val) {
      this.actor = val
    },
  },
})

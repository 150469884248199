<script setup>
const router = useRouter()

const clickStart = ref(false)
const clickMake = ref(false)

onMounted(() => {
  document.body.classList.add('index')
  window.scrollTo({ top: 0, behavior: 'smooth' })
})

onUnmounted(() => {
  document.body.classList.remove('index')
})

function start() {
  clickStart.value = !clickStart.value
  router.push('/intro')
}

function makeCharacter() {
  clickMake.value = !clickMake.value
  router.push('/select')
}
</script>

<template>
  <div class="w-full">
    <video id="background-video" autoplay muted playsinline poster="@/assets/vdo/home.png">
      <source src="https://m.fruitfit.site/home-mNuUmbub-2.mp4" type="video/mp4">
    </video>
    <img src="@/assets/vdo/home-guide.png" alt="home" class="bg-guide hidden">

    <div class="btn-wrap">
      <a class="btn" :class="{clicked: clickStart}" @click="start">
        <img src="@/assets/btn/start.png" alt="start">
      </a>
      <a class="btn" :class="{clicked: clickMake}" @click="makeCharacter">
        <img src="@/assets/btn/make-character.png" alt="make Character">
      </a>
    </div>
  </div>
</template>

<style scoped>
.btn-wrap {
  top: 76.2%;
}
</style>

<script setup>
import { useStore } from '@/stores'

const store = useStore()
const $api = inject('$api')

if (store.userData && store.accessToken) {
  // get profile
  const data = $api('profile', {
    ignoreResponseError: true,
    parseResponse: JSON.parse,
  })

  if (data.success) {
    store.setUserData(data.user)
  }
}
</script>

<template>
  <RouterView v-slot="{ Component }">
    <Transition name="fade">
      <Component :is="Component" />
    </Transition>
  </RouterView>
</template>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

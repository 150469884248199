import { createApp } from 'vue'
import './css/style.css'
import App from './App.vue'
import { createPinia } from 'pinia'
import router from '@/router'
import { $api } from './utils/api'
import HoneybadgerVue from '@honeybadger-io/vue'

const app = createApp(App)

const config = {
  apiKey: 'hbp_Xeg2fBH4JVHOvuFDkddzYBC06ncmyw2YivCN',
  environment: 'production',
}

if (import.meta.env.VITE_ENV === 'production') {
  app.use(HoneybadgerVue, config)
}

app.use(createPinia())
app.use(router)
app.provide('$api', $api)

app.mount('#app')

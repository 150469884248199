import { createRouter, createWebHistory } from 'vue-router'
// eslint-disable-next-line import/no-unresolved
import routes from '~pages'

const router = createRouter({
  history: createWebHistory(),
  routes,
})

// router.beforeEach(async to => {})
export default router
